import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Public',
    component: () => import('../views/Public.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: 'LOGIN page' },
    component: () => import('../views/Login.vue')
  },
  {
    path: '/user_manipulation',
    name: 'user_manipulation',
    component: () => import('../components/users/UserManipulation.vue')
  },
  {
    path: '/airport_manipulation',
    name: 'airport_manipulation',
    component: () => import('../components/superadmin/AirportManipulation.vue')
  },
  {
    path: '/showusers',
    name: 'ShowUsers',
    component: () => import('../views/ShowUsers.vue')
  },
  {
    path: '/deletedusers',
    name: 'deletedusers',
    component: () => import('../components/users/DeletedUsers.vue')
  },
  {
    path: '/mailmanipulation',
    name: 'mailmanipulation',
    component: () => import('../components/admin/MailManipulation.vue')
  },
  {
    path: '/notification',
    name: 'notification',
    component: () => import('../components/admin/Notification.vue')
  },
  {
    path: '/superadmin_settings',
    name: 'superadmin_settings',
    component: () => import('../views/SuperadminSettings.vue')
  },
  {
    path: '/log',
    name: 'log',
    component: () => import('../components/superadmin/Log.vue')
  },
  {
    path: '/showreports',
    name: 'ShowReports',
    component: () => import('../views/ShowReports.vue'),
    meta: { requiresAuth: 'true' }
  },
  {
    path: '/showreportssa',
    name: 'ShowReportsSA',
    component: () => import('../views/ShowReportsSA.vue'),
    meta: { requiresAuth: 'true' }
  },
  {
    path: '/adminsettings',
    name: 'AdminSettings',
    component: () => import('../views/AdminSettings.vue')
  },
  {
    path: '/persoinfo',
    name: 'PersoInfo',
    component: () => import('../components/users/PersoInfo.vue')
  },
  {
    path: '/airportmap',
    name: 'AirportMap',
    component: () => import('../views/AirportMap.vue')
  },
  {
    path: '/message_center',
    name: 'MessageCenter',
    component: () => import('../views/MessageCenter.vue')
  },
  {
    path: '/visitor',
    name: 'Visitor',
    component: () => import('../views/Visitor.vue')
  },
  {
    path: '/wlstatistics',
    name: 'WLstatistics',
    component: () => import('../views/WLstatistics.vue')
  },
  {
    path: '/duration',
    name: 'Duration',
    component: () => import('../views/Duration.vue')
  },
  {
    path: '/birdsstrikeCG',
    name: 'BirdsStrikeCG',
    component: () => import('../components/birdsStrike/cg/BirdsStrike.vue')
  },
  {
    path: '/birdsstrikeICAO',
    name: 'BirdsStrikeICAO',
    component: () => import('../components/birdsStrike/icao/BirdsStrike.vue')
  },
  {
    path: '/birdsstrikeBiH',
    name: 'BirdsStrikeBiH',
    component: () => import('../components/birdsStrike/bh/BirdsStrike.vue')
  },
  {
    path: '/birdsstrikestatistics',
    name: 'BirdsStrikeStatistics',
    component: () => import('../views/BirdsStrikeStatistics.vue')
  },
  {
    path: '/tracker',
    name: 'Tracker',
    component: () => import('../views/Tracker.vue')
  },
  {
    path: '/paas',
    name: 'paas',
    component: () => import('../views/Paas.vue')
  },
  {
    path: '/CreateAlert',
    name: 'CreateAlert',
    component: () => import('../components/paas/Create_Alert.vue')
  },
  {
    path: '/show_alert',
    name: 'show_alert',
    component: () => import('../components/paas/Show_Alert.vue')
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('../components/paas/Chat.vue')
  },
  {
    path: '/contact_settings',
    name: 'contact_settings',
    component: () => import('../components/paas/ContactSettings.vue')
  },
  {
    path: '/contact_manipulation',
    name: 'contact_manipulation',
    component: () => import('../components/paas_admin/ContactManipulation.vue')
  },
  {
    path: '/wlstat_listing',
    name: 'wlstat_listing',
    component: () => import('../components/wlstat/Listing.vue')
  },
  {
    path: '/wlstat_summary',
    name: 'wlstat_summary',
    component: () => import('../components/wlstat/Summary.vue')
  },
  {
    path: '/na_animal',
    name: 'na_animal',
    component: () => import('../components/wlstat/NA_Animal.vue')
  },
  {
    path: '/SOS',
    name: 'SOS',
    component: () => import('../components/wlstat/SOS.vue')
  },
  {
    path: '/trackerLive',
    name: 'TrackerLive',
    component: () => import('../views/TrackerLive.vue')
  },
  {
    path: '/Cannon',
    name: 'Cannon',
    component: () => import('../views/Cannon.vue')
  },
]

const router = new VueRouter({
  mode: "history", //sklanjanje # iz url-a
  routes
});

export default router;

