import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm"
import { ValidationObserver } from "vee-validate"
import VueSweetalert2 from "vue-sweetalert2"
import { BootstrapVue, IconsPlugin } from "bootstrap-vue"
import axios from 'axios'
import i18n from './components/i18n' //ucitavaj konfiguraciju iz foldera i18n/index.js
import VueKonva from 'vue-konva';
import VTooltip from 'v-tooltip';
import vmodal from 'vue-js-modal';
import GmapVue from 'gmap-vue';
require("../src/assets/style.css");
import VueGtag from 'vue-gtag'
import { LMap, LTileLayer, LMarker, LPopup , LCircleMarker} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-rotate';

// Global Error Handling for WebSocket Errors
window.addEventListener("error", (event) => {
  if (event.message.includes("WebSocket")) {
      // Optionally, filter out any token or sensitive information here if it is part of the error
      console.warn("WebSocket error intercepted and suppressed.");
      event.stopImmediatePropagation();
  }
});


axios.defaults.withCredentials = false;

Vue.prototype.$ValueSocket=true;

//BACKEND IP
Vue.prototype.$hostname = 'https://apitest.pistamatic.com/';
//Vue.prototype.$hostname = 'https://api.pistamatic.com/';
//Vue.prototype.$hostname = 'https://localhost:5000/';

axios.defaults.baseURL= Vue.prototype.$hostname + "api";


//custom HEADER
axios.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("Token") != null)
    //if(!config.url.includes('Public'))
    {
      config.headers["Authorization"] = "Bearer " + localStorage.getItem("Token");
    }
    else
    {
      delete axios.defaults.headers.Authorization;
    }
    return config;
  },
  (error) => {
    localStorage.setItem('TokenCheck',0);
    //localStorage.clear();
    //router.push("/Login");
    return Promise.reject(error)
  }
);


axios.interceptors.response.use(
  (response) => response,
  (error) => {
    //console.log("Uslo3")
    //catch potential problem with Token because axios return error
    if(localStorage.getItem('TokenCheck') == 0)
    {
      //console.log("Uslo4")
      localStorage.setItem('TokenCheck', 1); 
      return Promise.reject(error);
    }

    /*
    localStorage.setItem('TokenCheck', 1); //problem with Token
    hasErrorOccurred = true;

    if (hasErrorOccurred) {
      hasErrorOccurred = false;
      return Promise.reject(error); // Stop if an error has already occurred
    }
    */


    //return Promise.reject(error); 
    /*
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/Login";
    }

    if (error.response.status === 403) {
      //PRIKAZ PORUKE
      Swal.fire({
        title: "Your session is expired. Please, login again...",
        icon: 'warning',
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        width: "450px",
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }).then(function()
      {
        localStorage.clear();
        window.location.href = "/Login";
      })
    }*/
  }
);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

import swal from "sweetalert2";
window.Swal = swal;

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(VueSweetalert2);
Vue.use(VueKonva);
Vue.use(VTooltip);
Vue.use(vmodal, { dynamicDefault: { draggable: true, resizable: true } })

Vue.prototype.$axios = axios;

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyDeMgrPAzhP-29PuvTtxpccN_xOaZL_vcI',
    v: '3.55',
    libraries: 'places,drawing,visualization',
    async: true
  },
  installComponents: true,
  dynamicLoad: false,
})

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-popup', LPopup);
Vue.component('l-circle-marker', LCircleMarker);

Vue.config.productionTip = false;


//cache map
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js')
      .then((registration) => {
        //console.log('ServiceWorker registration successful with scope: ', registration.scope);
      })
      .catch((error) => {
        console.log('ServiceWorker registration failed: ', error);
      });
  });
}
//end of block


Vue.use(vuetify, {
  iconfont: "md",
});

Vue.use(VueGtag, {
  config: {
      id: 'G-6ZT6CKX6GY',
      params: {
          send_page_view: false,
          anonymize_ip: true
      }
  }
}, router)


new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
