<template>
  <v-app id="inspire">

    <v-main>
      <!-- If using vue-router -->
      <router-view></router-view>

      <!-- SESSION EXPIRED -->  
      <template v-if="this.overlayMask==1"><v-overlay/></template>
      <modal name="TokenSession" :clickToClose="false" :draggable="false" classes="modal-window-stopalert" :width="350" height="120" :shiftY="0.5" :shiftX="0.5">
        
        <div style="background-color: #838080; height: 30px; margin: -5px -10px 0 -10px !important;">
          <div id="title-naslov-modalni" style="padding-top: 5px !important;"><strong>PISTAMATIC login window</strong></div>
        </div>

        <div style="padding-left: 5px;">   
        </div>

        <div style="width: 100%; text-align: center; margin-top: 10px !important;">
            <span style="font-size: 13px; color: #000;">Your session expired. Please, login again...</span> 
            <div style="height: 10px;"></div>
            <button id="plavo_dugme80" @click="session()">{{$t('button.ok')}}</button> 
        </div>
      </modal>
      <!-- END OF SESSION EXPIRED-->

    </v-main>
  </v-app>
</template>


<script>
export default {
  data() {
      return {
        overlayMask: 0
    }
  },
  async created () {
    // Global Error Handling in Vue Component (optional)
    window.onerror = function(message, source, lineno, colno, error) {
        if (message.includes("WebSocket")) {
            console.warn("WebSocket error intercepted and suppressed.");
            return true;  // Prevent the error from being logged in the console
        }
        return false;  // Allow other errors to show in the console
    };

    setInterval(() => {
        if(localStorage.getItem('TokenCheck') == 1)
        {
          this.$axios.get('CheckToken')
          .then(r => { 
            //console.log("Podaci: " + r.status)
            if(r.status !== 200)
            {
                //console.log("Uslo1")
                this.overlayMask=1;
                this.$modal.show('TokenSession');
                localStorage.setItem('TokenCheck',0);
            }
            else
            {
              localStorage.setItem('TokenCheck',0);
            }
            /*
            console.log("Success")
            if(r.data.length >= 0)
            {
              //localStorage.setItem('TokenCheck', 1);
            }
              */
          })
          .catch(error => {
            //console.log("Uslo2")
              this.overlayMask=1;
              this.$modal.show('TokenSession');
              localStorage.setItem('TokenCheck',0);
          });
        }

      }, 1000);
  },
  methods: {
    session(){
      this.overlayMask=0;
      this.$modal.hide('TokenSession');
      localStorage.clear();
      window.location.href = "/Login";
    },
  }
}

</script>


<style>
  @import '../src/assets/style.css';
  @import 'https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css';
</style>